<template>
  <div id="static">
    <div class="title">User Agreement</div>
    <div class="content">
      <p style="text-align: center">
        <strong>User agreement of the limited company La 2 Dream</strong>
      </p>
      <p style="text-align: left">
        This Agreement is designed to regulate the relationship between the user and the copyrighter
        of the Internet Portal <strong>la2dream.com</strong>
      </p>
      <p style="text-align: left">
        <strong>Content:</strong><br />1. Defintions.<br />2. Subject of the Agreement.<br />3.
        Terms for entry of the Agreement into a force .<br />4. Rights and Obligations of the
        Parties<br />4.1 User Rights.<br />4.2 User Obligations.<br />4.3 User does not have the
        right to:<br />4.4 Operator Rights.<br />4.5 Operator Obligations.<br />4.6 Limitation of
        liability of the Operator.<br />4.7 The Operator does not guarantee:<br />5. Privacy and
        Security.<br />6. Additional Paid Services.<br />7. Disclaimer of Warranties<br />8.
        Additional provisions.
      </p>
      <p style="text-align: left">
        <strong>1. Defintions</strong><br />«Operator» — the owner of la2dream.com Portal, is a
        Party to the User Agreement. Operator performs administration and maintenance, provides
        users access to the portal, services (also paid services), under the terms of this
        agreement.<br />«User.» means an individual (natural person) that has completed the
        registration on la2dream.com Portal or participating in a Portal projects
      </p>
      <p style="text-align: left">«User» and «Operator» are the Parties to the Agreement.</p>
      <p style="text-align: left">
        «Portal» — special software and hardware complexes placed on the Operator’s resources. User
        access to the portal is provided only by the Operator. All rights to use this portal belong
        exclusively to the Operator.
      </p>
      <p style="text-align: left">
        «Site» is a website located on the Internet at https://la2dream.com.
      </p>
      <p style="text-align: left">
        «Services» means providing Users with access to the Portal, using opportunities and
        services, participating in projects on the terms and conditions specified in the Agreements.
        Operator’s services are provided free of charge. Exceptions are only Additional Paid
        Services. All Services are provided by the operator only within the Portal, i.e. During its
        use by the User.
      </p>
      <p style="text-align: left">
        «Additional paid services» means providing the User with additional special features of the
        Portal for a fee. These services are not mandatory and are provided at the request of the
        User. Paid Services are also provided by the Operator exclusively within the Portal.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>2. Subject of the Agreement</strong><br />2.1. Operator provides access to the
        Portal (Services, Paid Services) to an unlimited number of persons on the terms of this
        Agreement.
      </p>
      <p style="text-align: left">
        2.2. The user is aware that the main purpose of project is to portal la2dream.com
        organization operator of leisure and entertainment, is in no way associated with gambling.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>3. Terms for entry of the Agreement into a force</strong><br />3.1. From the moment
        of acceptance of this Agreement, the User has the Rights and performs the Obligations
        stipulated in the text of this Agreement.
      </p>
      <p style="text-align: left">
        3.2. The User accepts and agrees to the terms of this Agreement by registering the account
        on the site: la2dream.com. The fact of acceptance means the full and unconditional consent
        of the User with all the conditions and annexes of this Agreement.
      </p>
      <p style="text-align: left">
        3.3. If the User for any reason does not agree with the terms and conditions of this
        Agreement, he must stop further use of the Site and Operator’s Services.
      </p>
      <p style="text-align: left">
        3.4. Use Operator’s Site and Services possible only after acceptance of this Agreement by
        the User.
      </p>
      <p style="text-align: left">
        3.5. By accepting this Agreement, the User confirms his legal capacity, the right to enter
        into this Agreement. The operator does not have to check the data specified by the user at
        registration.
      </p>
      <p style="text-align: left">
        3.6. If you need to create an account (hereinafter «Account») to use the information and
        entertainment portal la2dream.com or participate in portal projects, the User must complete
        the registration process by providing the Operator with up-to-date, complete and accurate
        information (including e-mail) in the relevant Form. In case of posting on the portal
        la2dream.com messages about participation in partner programs, the registered user has the
        right to participate in contests, drawings, competitions on the Site of partners
        la2dream.com
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>4. Rights and Obligations of the Parties</strong>
      </p>
      <p style="text-align: left"><strong>4.1. User Rights.</strong></p>
      <p style="text-align: left">In accordance with this agreement, the User has the Rights:</p>
      <p style="text-align: left">To use the Portal only for personal, non-commercial purposes.</p>
      <p style="text-align: left">
        To use all services, resources (including Additional Paid Services) provided by the
        Operator.
      </p>
      <p style="text-align: left">
        If necessary, get help from technical support of the site, ask Operator about the issues
        that arise, through contact information or feedback form.
      </p>
      <p style="text-align: left">
        Free of charge (excluding the provision of Additional Paid Services) to use the site and
        participate in the projects of the Portal.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left"><strong>4.2. User Obligations.</strong></p>
      <p style="text-align: left">
        Complying with the terms of this agreement, the User is obliged:
      </p>
      <p style="text-align: left">Provide reliable information when registering on the Site.</p>
      <p style="text-align: left">
        The User shall be responsible for ensuring non-disclosure (confidentiality) of the data
        stored in his/her account, his/her login and passwords and bear liability for any failure to
        keep confidential and disclose in any way his/her account data.
      </p>
      <p style="text-align: left">
        If necessary, at the request of the Operator, provide confirmation of their personal data
        indicated on the Site at the time of registration.
      </p>
      <p style="text-align: left">Follow the Operator’s instructions within this Portal.</p>
      <p style="text-align: left">
        Do not violate the rights of author and intellectual property of the Operator, located on
        its Portal.
      </p>
      <p style="text-align: left">
        Comply with all conditions of this Agreement without limitations.
      </p>
      <p style="text-align: left">
        The User undertakes to use the Portal exclusively for entertainment purposes, without
        pursuing any gain from the Portal.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>4.3. User does not have the right to:</strong>
      </p>
      <p style="text-align: left">
        Using the Operator Portal, the User does not have the right to:
      </p>
      <p style="text-align: left">
        — Use errors (bugs) of the Site and Services of the Portal. Get unauthorized access to the
        database, computer system, modify the program code. The User is prohibited from using
        malicious programs that can cause damage the Portal, as well as special software that gives
        him superiority over other users. If such and similar violations are detected, the Operator
        has the right to impose penalties on the User, including the prohibition of access to Sites
        and the deletion of the account.<br />— Restrict access to the sites of other Users.<br />—
        Cheating and other illegal actions.<br />— To advertise anything that does not relate to the
        Portal without the written permission of the Operator.<br />— It is forbidden use profanity,
        express threats to the Operator or other Users, disseminate materials that promote violence,
        racial hatred, dislike of religious beliefs, containing pornographic information,
        advertising of drugs, calling for the violent overthrow of power.<br />— Conduct
        anti-advertising on the Portal, including on other resources<br />— The User agrees that his
        Rights and Obligations can be changed / supplemented by the Operator, which he is notified
        by the contact information specified at the time of registration (or on the Site)
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left"><strong>4.4. Operator Rights.</strong></p>
      <p style="text-align: left">This Agreement gives to Operator the following Rights:</p>
      <p style="text-align: left">
        At any time, at its discretion, unilaterally without prior notice to Users, expand, modify,
        terminate, restrict the provision of the Services, as well as the Additional Paid Services.
      </p>
      <p style="text-align: left">
        Manage all processes on the Portal only at their own discretion. Suspend, change the course
        of any processes without notifying the User in advance.
      </p>
      <p style="text-align: left">
        Apply penalties to the User in case of violation of this Agreement.
      </p>
      <p style="text-align: left">Delete / modify the User information posted on the Portals.</p>
      <p style="text-align: left">
        To monitor, store identification and statistical information about the User.
      </p>
      <p style="text-align: left">
        To send to the Users technical, advertising and other information concerning the Portal,
        Services and Paid Services.
      </p>
      <p style="text-align: left">
        Inform, warn, make observations, notify the User in case of non-observance / violation of
        the terms of this Agreement. All instructions of the Operator must be strictly adhered to.
      </p>
      <p style="text-align: left">
        Take legal measures to protect their intellectual, copyright property.
      </p>
      <p style="text-align: left">
        Modify, change, supplement the Portal on your own, without warning the User in advance.
      </p>
      <p style="text-align: left">
        Inaction of the Operator for violation of this Agreement by the User does not exclude the
        use of penalties for them later.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>4.5. Operator Obligations.</strong>
      </p>
      <p style="text-align: left">As a party to the User Agreement, the Operator shall:</p>
      <p style="text-align: left">
        Make possible that the User can receive the Operator’s Services within this Portal
        (including the receipt of Additional Paid Services).
      </p>
      <p style="text-align: left">
        Answer the Users’ questions, if there are any disputable situations, take all measures to
        resolve them.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>4.6. Limitation of liability of the Operator.</strong>
      </p>
      <p style="text-align: left">
        In accordance with this section, the Operator is not responsible for:
      </p>
      <p style="text-align: left">
        Any damage caused or which can only be caused to the personal data and computer of the User
        related with the use of the Portal and the Site
      </p>
      <p style="text-align: left">
        Losses (direct / indirect) caused to the User related to the use or inaccessibility of the
        Portal (inability to use it), the behavior of third parties on the Portal, as well as other
        participants in the Portal’s projects, unauthorized access to the User’s personal data.
      </p>
      <p style="text-align: left">
        Speech, disseminated information, User’s statements and other unlawful acts carried out by
        him on the Portal and beyond.
      </p>
      <p style="text-align: left">
        The information specified by the User at registration, the lost possibility of access to the
        Portal (login, password, etc.).
      </p>
      <p style="text-align: left">
        The User’s loss of acquired virtual values, as a result of the provision of Services and
        Additional Paid Services by the Operator.
      </p>
      <p style="text-align: left">
        Payment by the User of Additional Paid Services and related expenses.
      </p>
      <p style="text-align: left">Continuous and trouble-free work of the Portal.</p>
      <p style="text-align: left">
        Possibilities of the User, related with Internet connection, data transmission speed.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>4.7. The Operator does not guarantee:</strong>
      </p>
      <p style="text-align: left">
        Continuous, reliable, error-free operation of the Portal, access to its resources, Services,
        including Additional Paid Services
      </p>
      <p style="text-align: left">
        The fact that the Portal will full compliance with the requirements and representations of
        the User.
      </p>
      <p style="text-align: left">
        That the quality of the provided Services (Additional Paid Services) will meet the
        expectations of the User.
      </p>
      <p style="text-align: left">
        The Operator is not obliged to provide documents and other evidence indicating the violation
        (by the User) of this Agreement and the application of penalty / disciplinary sanctions
        against the User upon the User’s request.
      </p>
      <p style="text-align: left">
        The User uses the Portal, the Operator’s Site solely at his own risk, of his own free will,
        without coercion. He understands the possible risks associated with the use of the
        Operator’s resources and has no claims to the Operator.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>5. Privacy and Security.</strong><br />5.1. Confidential Information — information
        received by the operator in the process of registering the User on the Site, as well as
        during visits to the Sites / Portal and participation in the events of the Portal.
      </p>
      <p style="text-align: left">
        5.2. Confidential information is not subject to disclosure and transfer to third parties.
      </p>
      <p style="text-align: left">
        5.3. Personal data can be transferred by the Operator only in the following cases:
      </p>
      <p style="text-align: left">
        -Official request of law enforcement agencies (violation of local and international
        legislation).
      </p>
      <p style="text-align: left">-Personal will of the User.</p>
      <p style="text-align: left">
        -Inability to use the Services and Additional Paid Services on the Portals (which the User
        is warned about in advance).
      </p>
      <p style="text-align: left">
        -Violations of the clauses of this Agreement (at the discretion of the Operator).
      </p>
      <p style="text-align: left">
        5.4. The operator ensures the safety of the user’s personal data using special software. In
        case of unauthorized access to the Portal / Site of third parties, personal data security is
        not guaranteed.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>6. Additional Paid Services.</strong><br />6.1. At the request of the User, the
        Operator provides him with Additional Paid Services. They allow you to use the advanced
        features of the Portal.
      </p>
      <p style="text-align: left">
        6.2. Additional Paid Services are not a prerequisite for using the Portal and participating
        in the Portal’s projects.
      </p>
      <p style="text-align: left">
        6.3. Since debiting of funds by the Portal Operator from the User’s account, the additional
        Paid Service is considered to be rendered in full, of the proper quality.
      </p>
      <p style="text-align: left">6.4. Money refund may due in 15 days after payment service.</p>
      <p style="text-align: left">
        6.5. The User agrees that the Operator has the right to store the personal information
        received when User purchase the Additional Paid Services.
      </p>
      <p style="text-align: left">
        6.6. The list of Additional Paid Services and their cost are published only by the Operator
        on the Site / Portal. Any information and offers of purchase posted on third-party resources
        are considered fraud, for which the Operator does not bear responsibility.
      </p>
      <p style="text-align: left">
        6.7. The methods and the possibility of purchasing Additional Paid Services are explained by
        the Operator on the Site.
      </p>
      <p style="text-align: left">
        6.8. The operator does not give explanations on the issues of working with payment systems
        with which the User has decided to purchase Additional Paid Services, and is not responsible
        for their correct operation.
      </p>
      <p style="text-align: left">
        6.9. In a cases of a technical malfunction of the Sites or intentional actions of the User,
        or in other cases when Additional Paid Services were rendered without full / partial
        debiting of funds from the User’s account, he is obliged to report this fact to the
        Operator. After that, the User is obliged to repay the arrears.
      </p>
      <p style="text-align: left">
        6.10. The Operator does not reimburse the User for unused (partially used) Additional Paid
        Services.
      </p>
      <p style="text-align: left">
        6.11. The user at his own expense, independently carries all the financial costs associated
        with the acquisition (transfer of money) of Additional Paid Services. This section includes:
        commissions, fees and other expenses.
      </p>
      <p style="text-align: left">
        6.12. The User guarantees to the Operator that he has all legitimate power and rights to
        conclude this Agreement in the part of Additional Paid Services.
      </p>
      <p style="text-align: left">
        6.13. In the case of purchasing Additional Paid Services by a User who has not reached the
        age of 18, he must first obtain consent to conduct a financial transaction with legal
        representatives. The fact of purchasing Additional Paid Services is a confirmation of the
        receipt of such consent from the legal representative. If necessary, the Operator has the
        right to request a written confirmation of the receipt of consent and the provision of
        passport data to determine the true age of the User.
      </p>
      <p style="text-align: left">
        6.14. Responsibility for the purchase of Additional Paid Services is entirely the
        responsibility of the User and his legal representatives.
      </p>
      <p style="text-align: left">
        6.15. Disputes about responsibility for the purchase of Additional Paid Services with the
        Portal Operator are not allowed.
      </p>
      <p style="text-align: left">
        6.16. Receipt of additional Paid Services by the User is possible only after full payment of
        their cost.
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>7. Disclaimer of Warranties.</strong><br /><span style="font-size: small"
          ><strong
            >ALL SERVICES ON PORTALS ARE PROVIDED TO USERS UNDER THE INSTALLED CONCEPT «AS IS» .
            PORTAL DISCLAIMS THE WARRANTY OF RELATING TO SERVICES OR INGAME ITEMS.</strong
          ></span
        >
      </p>
      <p style="text-align: left">
        <span style="font-size: small"
          ><strong
            >OPERATOR HEREBY WARNS AND REMINDS THAT EXCESSIVE USE OF PC, INCLUDING PLAY ON PC, CAN
            BE HARMFUL FOR THE USER’S HEALTH. THE USER OR ITS LEGAL REPRESENTATIVES, TRUSTEE HEREBY
            AGREE TO BE RESPONSIBLE FOR CONTROLLING THE STATE OF HIS/HER HEALTH AND NOT TO USE THE
            SERVICES / RESTRICT THEIR USE IN CASE OF ANY CONTRAINDICATIONS.</strong
          ></span
        >
      </p>
      <p style="text-align: left">
        <span style="font-size: small"
          ><strong
            >OPERATOR SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL, OR OTHER
            DAMAGES (INCLUDING WITHOUT LIMITATION, LOST PROFITS) RESULTING FROM THE GAME SERVICES,
            OPERATOR’S PORTAL AND/OR MATERIALS ON THE SITES, THIRD PARTIES’ ACTIONS RELATED TO THE
            GAME SERVICES AND OPERATOR’S PORTAL AND/OR MATERIALS ON THE SITES, INCLUDING FRAUDULENT
            AND/OR NEGLIGENT ACTIONS OF THIRD PARTIES.</strong
          ></span
        >
      </p>
      <p style="text-align: left">&nbsp;</p>
      <p style="text-align: left">
        <strong>8. Additional provisions.</strong><br />8.1. If the User does not have the right to
        use the Portal according to the laws of his country or there are other restrictions (age
        limits and others), he is obligated to refuse to use the Portal without warning, as well as
        their separate services. The user assumes all responsibility of using the Portal in his
        country, based on local laws and taking into account international legislation.
      </p>
      <p style="text-align: left">
        8.2. Invalidity of one / several items / sections of the User Agreement does not entail its
        invalidity as a whole. In this case, the Parties must fulfill their obligations under the
        remaining items / sections of the Agreement.
      </p>
      <p style="text-align: left">
        8.3. Disputes arising between the Parties are shall be settled on a pre-trial basis through
        correspondence between the Operator and the User. In case of ineffective settlement of
        disputes, they will be resolved in accordance with the legislation of the Republic of
        Latvia.
      </p>
      <p style="text-align: left">
        8.4. This Agreement may be amended, supplemented by the Operator without prior notice to the
        User. Any changes take effect immediately after the publication of the amended version of
        the Agreement on the Site. To avoid disputes, the User undertakes to independently verify
        the text of the Agreement on the Site, where it is freely available. In case of failure to
        verify the text of the Agreement by the User, this fact can not serve as a basis for
        refusing to fulfill the obligations undertaken. The amended version of the Agreement after
        publication on the Site has a similar legal effect with the original text.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAgreement',
};
</script>

<style scoped>
.title {
  text-align: center;
}

p {
  font-size: 1.2em;
}
</style>
